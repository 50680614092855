<div class="modal-body" id="popupmodel">
    <div class="backrow" [style.background-color]="color">
    </div>
    <div class=" d-flex justify-content-center">

        <div class="cerclrinfo">
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.6008 33.8234C21.7733 39.1898 13.4622 36.5625 10.2851 34.578C8.09201 35.3327 3.55408 36.842 2.94678 36.842C2.33947 36.842 2.07518 36.3389 2.01895 36.0873L4.38069 28.5408C-0.95012 18.613 3.90271 10.2616 6.99548 7.3268C9.58216 4.5877 16.8305 -0.102306 25.1304 3.05045C33.4302 6.20321 36.2362 12.9727 36.6017 15.9633C37.5295 19.6807 37.4283 28.457 29.6008 33.8234Z" stroke="#04A6BC" stroke-width="2"/>
            <path d="M10.3692 13.9509H22.8528C23.3926 13.9509 23.64 14.5099 23.6963 14.7894V17.2211C24.7366 16.6062 27.0533 15.1919 27.998 14.454C28.9427 13.7161 29.2914 14.5379 29.3476 15.041V24.2645C29.3476 25.2707 28.5603 25.075 28.1667 24.8514L23.6963 21.9167C23.7244 22.5036 23.7637 23.8452 23.6963 24.516C23.6288 25.1868 23.1058 25.2986 22.8528 25.2707H10.3692C9.62697 25.2707 9.44141 24.7676 9.44141 24.516V14.7894C9.44141 14.1186 10.06 13.9509 10.3692 13.9509Z" stroke="#04A6BC" stroke-width="2"/>
          </svg>


        </div>
    </div>
    <div class="styletext">
        <span>{{texte |translate}}</span>
    </div>
    <div class="chatmsg text-center" *ngIf="chatinfo">
        <p>{{title}}</p>
    </div>
    <div>
        <div class="chatmsg text-center">
            <p> {{'v_call_text' | translate }}
            </p>
        </div>
        <div class="marg">
            <a *ngIf="tel && allowTel == '1'" class="border-0" href="tel://{{tel.trim()}}" target="_blank" style="outline: none;">
                <button type="button" class="bluebutton btn  btn-block">
              <label  class ="continue" >{{'finder_label_callstart'|translate}}</label>
            </button>
            </a>
            <button type="button" class="bluebutton btn  btn-block  mt-2" (click)="startVideoCall()">
            <label  class ="continue" > {{ 'start_call' | translate }} </label>
          </button>

            <button type="button" class="btn btn-block mt-2" style="border: 1px solid #E0E0E0;
          " role="button" (click)="closemodel()">
            <label  class ="Rejecmessage">{{'label_button'|translate}}</label>
            </button>
        </div>
    </div>

</div>