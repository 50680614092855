<div id="landing-page-object">
    <div class="landing-page-content">
        <p class="text-center">{{'acc_setup_v1' | translate}}</p>
        <form [formGroup]="formulaire" (ngSubmit)="onsubmit()">
            <div class="d-flex justify-content-center mb-2">
                <span class="w-100 pr-2">
                  <input [placeholder]="'editprofil_general_label_firstname' | translate" name="firstname" formControlName="firstname" class="firstNameInput" required />
                    <span *ngIf="(formulaire.controls['firstname'].invalid && formulaire.controls['firstname'].touched) || formulaire.controls['firstname'].dirty">
                        <small class="d-block" *ngIf="formulaire.controls['firstname'].errors?.required" class="text-danger">
                          {{ "inputchecker_label_firstnamerequired" | translate }}
                        </small>
                        <small class="d-block" *ngIf="formulaire.controls['firstname'].errors?.pattern" class="text-danger">
                          {{ "inputchecker_label_firstnamemsg" | translate }}
                        </small>
                    </span>
                </span>
                <span class="w-100">
                    <input [placeholder]="'registration_input_lastname' | translate" name="lastname" formControlName="lastname" required class="firstNameInput" />
                      <span class="d-block" *ngIf="(formulaire.controls['lastname'].invalid && formulaire.controls['lastname'].touched) || formulaire.controls['lastname'].dirty">
                          <small *ngIf="formulaire.controls['lastname'].errors?.required" class="text-danger">{{"inputchecker_label_lastnamerequired" | translate}}</small>
                          <small class="d-block" *ngIf="formulaire.controls['lastname'].errors?.pattern" class="text-danger">
                            {{ "inputchecker_label_lastnamemsg" | translate }}
                          </small>
                      </span>
                </span>
            </div>
            <div class="mb-2">
                <input [placeholder]="'mail_exaple_landpage_v' | translate" name="email" formControlName="email" class="mailInput" />
                <span *ngIf="(formulaire.controls['email'].invalid &&formulaire.controls['email'].touched) || formulaire.controls['email'].dirty">
                    <small *ngIf="formulaire.controls['email'].errors?.required" class="text-danger">
                      {{"inputchecker_label_emailrequired" | translate }}
                    </small>
                    <small *ngIf="formulaire.controls['email'].errors?.pattern" class="text-danger">
                      {{"chatbot_script_medical_validemail" | translate }}
                    </small>
                </span>
            </div>
            <div class="d-flex">
                <span class="codephone-size mr-2">
                    <input type="text" placeholder="+1" class="codephone-size" formControlName="code_phone" />
                </span>
                <span class="phone-size mr-1">
                  <input placeholder="212-555-1212" class="phone-size" formControlName="mobile" />
                  <span class="d-block text-nowrap">
                    <span *ngIf="((formulaire.controls['mobile'].invalid &&formulaire.controls['mobile'].touched) || formulaire.controls['mobile'].dirty) ||
                    (formulaire.controls['code_phone'].invalid &&formulaire.controls['code_phone'].touched) || formulaire.controls['code_phone'].dirty">
                    <small *ngIf="formulaire.controls['code_phone'].errors?.required" class="text-danger">
                      {{'phone_code_required' | translate }}Code phone required
                    </small>
                    <small *ngIf="(formulaire.controls['mobile'].errors?.pattern || formulaire.controls['code_phone'].errors?.pattern)" class="text-danger">
                      {{"pets_label_verifyphone" | translate }}
                    </small>
                  </span>
                </span>
                </span>
                <span class="phone-text-size text-nowrap">
                  {{'callsellphone' |translate}}
                </span>
            </div>
            <div style="display: flex; flex-direction: row">
                <input type="password" [placeholder]="'yourpasstxtchanp'|translate" name="password" class="passwordInput mr-1" formControlName="password" type="{{ showPassword ? 'text' : 'password' }}" required [(ngModel)]="password" />
                <svg *ngIf="showPassword === false" (click)="showPassword = !showPassword" width="22" height="31" class="mt-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.2714 9.17799C20.9814 8.71799 20.6714 8.28799 20.3514 7.88799C20.2641 7.77733 20.1544 7.68637 20.0295 7.62108C19.9046 7.55579 19.7673 7.51764 19.6266 7.50914C19.4859 7.50065 19.345 7.52199 19.2131 7.57177C19.0813 7.62154 18.9614 7.69864 18.8614 7.79799L15.8614 10.798C16.0814 11.458 16.1214 12.218 15.9214 13.008C15.7427 13.7071 15.3792 14.3453 14.8689 14.8555C14.3587 15.3658 13.7205 15.7293 13.0214 15.908C12.2314 16.108 11.4714 16.068 10.8114 15.848L8.35141 18.308C7.85141 18.808 8.01141 19.688 8.68141 19.948C9.75141 20.358 10.8614 20.568 12.0014 20.568C13.7814 20.568 15.5114 20.048 17.0914 19.078C18.7014 18.078 20.1514 16.608 21.3214 14.738C22.2714 13.228 22.2214 10.688 21.2714 9.17799ZM14.0204 9.97999L9.98041 14.02C9.47041 13.5 9.14041 12.78 9.14041 12C9.14041 10.43 10.4204 9.13999 12.0004 9.13999C12.7804 9.13999 13.5004 9.46999 14.0204 9.97999Z" fill="#C0C0C0" />
                  <path d="M18.25 5.75018L14.86 9.14018C14.486 8.7628 14.0403 8.46401 13.5492 8.26137C13.058 8.05873 12.5313 7.95632 12 7.96018C9.76 7.96018 7.96 9.77018 7.96 12.0002C7.96 13.1202 8.41 14.1302 9.14 14.8602L5.76 18.2502H5.75C4.64 17.3502 3.62 16.2002 2.75 14.8402C1.75 13.2702 1.75 10.7202 2.75 9.15018C3.91 7.33017 5.33 5.90018 6.91 4.92018C8.49 3.96018 10.22 3.43018 12 3.43018C14.23 3.43018 16.39 4.25018 18.25 5.75018ZM14.858 11.9982C14.858 13.5682 13.578 14.8582 11.998 14.8582C11.938 14.8582 11.888 14.8582 11.828 14.8382L14.838 11.8282C14.858 11.8882 14.858 11.9382 14.858 11.9982Z"fill="#C0C0C0" />
                  <path d="M21.7689 2.22891C21.4689 1.92891 20.9789 1.92891 20.6789 2.22891L2.22891 20.6889C1.92891 20.9889 1.92891 21.4789 2.22891 21.7789C2.3001 21.8499 2.38468 21.9059 2.47775 21.9439C2.57081 21.9818 2.6705 22.0009 2.771 22C2.8715 21.999 2.97081 21.9781 3.06316 21.9385C3.15551 21.8988 3.23904 21.8412 3.30891 21.7689L21.7689 3.30891C22.0789 3.00891 22.0789 2.52891 21.7689 2.22891Z" fill="#C0C0C0" />
                  </svg>
                <svg *ngIf="showPassword === true" (click)="showPassword = !showPassword" width="22" height="31" viewBox="0 0 24 24" class="mt-1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.25 9.15018C18.94 5.52017 15.56 3.43018 12 3.43018C10.22 3.43018 8.49 3.95018 6.91 4.92018C5.33 5.90018 3.91 7.33018 2.75 9.15018C1.75 10.7202 1.75 13.2702 2.75 14.8402C5.06 18.4802 8.44 20.5602 12 20.5602C13.78 20.5602 15.51 20.0402 17.09 19.0702C18.67 18.0902 20.09 16.6602 21.25 14.8402C22.25 13.2802 22.25 10.7202 21.25 9.15018ZM12 16.0402C9.76 16.0402 7.96 14.2302 7.96 12.0002C7.96 9.77018 9.76 7.96018 12 7.96018C14.24 7.96018 16.04 9.77018 16.04 12.0002C16.04 14.2302 14.24 16.0402 12 16.0402Z" fill="#C0C0C0" />
                    <path d="M11.9976 9.14014C11.2404 9.14014 10.5142 9.44093 9.97879 9.97635C9.44337 10.5118 9.14258 11.2379 9.14258 11.9951C9.14258 12.7523 9.44337 13.4785 9.97879 14.0139C10.5142 14.5493 11.2404 14.8501 11.9976 14.8501C13.5676 14.8501 14.8576 13.5701 14.8576 12.0001C14.8576 10.4301 13.5676 9.14014 11.9976 9.14014Z" fill="#C0C0C0" />
                </svg>
            </div>
            <div style="display: flex; flex-direction: row">
                <input type="password" [placeholder]="'yourpasstxtchanpconfirm'|translate" name="confirmpassword" class="passwordInput mr-1" formControlName="confirmpassword" type="{{ showPassword ? 'text' : 'password' }}" required [(ngModel)]="confirmpassword" />
                <svg *ngIf="showPassword === false" (click)="showPassword = !showPassword" width="22" height="31" class="mt-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.2714 9.17799C20.9814 8.71799 20.6714 8.28799 20.3514 7.88799C20.2641 7.77733 20.1544 7.68637 20.0295 7.62108C19.9046 7.55579 19.7673 7.51764 19.6266 7.50914C19.4859 7.50065 19.345 7.52199 19.2131 7.57177C19.0813 7.62154 18.9614 7.69864 18.8614 7.79799L15.8614 10.798C16.0814 11.458 16.1214 12.218 15.9214 13.008C15.7427 13.7071 15.3792 14.3453 14.8689 14.8555C14.3587 15.3658 13.7205 15.7293 13.0214 15.908C12.2314 16.108 11.4714 16.068 10.8114 15.848L8.35141 18.308C7.85141 18.808 8.01141 19.688 8.68141 19.948C9.75141 20.358 10.8614 20.568 12.0014 20.568C13.7814 20.568 15.5114 20.048 17.0914 19.078C18.7014 18.078 20.1514 16.608 21.3214 14.738C22.2714 13.228 22.2214 10.688 21.2714 9.17799ZM14.0204 9.97999L9.98041 14.02C9.47041 13.5 9.14041 12.78 9.14041 12C9.14041 10.43 10.4204 9.13999 12.0004 9.13999C12.7804 9.13999 13.5004 9.46999 14.0204 9.97999Z" fill="#C0C0C0" />
                    <path d="M18.25 5.75018L14.86 9.14018C14.486 8.7628 14.0403 8.46401 13.5492 8.26137C13.058 8.05873 12.5313 7.95632 12 7.96018C9.76 7.96018 7.96 9.77018 7.96 12.0002C7.96 13.1202 8.41 14.1302 9.14 14.8602L5.76 18.2502H5.75C4.64 17.3502 3.62 16.2002 2.75 14.8402C1.75 13.2702 1.75 10.7202 2.75 9.15018C3.91 7.33017 5.33 5.90018 6.91 4.92018C8.49 3.96018 10.22 3.43018 12 3.43018C14.23 3.43018 16.39 4.25018 18.25 5.75018ZM14.858 11.9982C14.858 13.5682 13.578 14.8582 11.998 14.8582C11.938 14.8582 11.888 14.8582 11.828 14.8382L14.838 11.8282C14.858 11.8882 14.858 11.9382 14.858 11.9982Z" fill="#C0C0C0" />
                    <path d="M21.7689 2.22891C21.4689 1.92891 20.9789 1.92891 20.6789 2.22891L2.22891 20.6889C1.92891 20.9889 1.92891 21.4789 2.22891 21.7789C2.3001 21.8499 2.38468 21.9059 2.47775 21.9439C2.57081 21.9818 2.6705 22.0009 2.771 22C2.8715 21.999 2.97081 21.9781 3.06316 21.9385C3.15551 21.8988 3.23904 21.8412 3.30891 21.7689L21.7689 3.30891C22.0789 3.00891 22.0789 2.52891 21.7689 2.22891Z" fill="#C0C0C0" />
                </svg>
                <svg *ngIf="showPassword === true" (click)="showPassword = !showPassword" width="22" height="31" viewBox="0 0 24 24" class="mt-1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.25 9.15018C18.94 5.52017 15.56 3.43018 12 3.43018C10.22 3.43018 8.49 3.95018 6.91 4.92018C5.33 5.90018 3.91 7.33018 2.75 9.15018C1.75 10.7202 1.75 13.2702 2.75 14.8402C5.06 18.4802 8.44 20.5602 12 20.5602C13.78 20.5602 15.51 20.0402 17.09 19.0702C18.67 18.0902 20.09 16.6602 21.25 14.8402C22.25 13.2802 22.25 10.7202 21.25 9.15018ZM12 16.0402C9.76 16.0402 7.96 14.2302 7.96 12.0002C7.96 9.77018 9.76 7.96018 12 7.96018C14.24 7.96018 16.04 9.77018 16.04 12.0002C16.04 14.2302 14.24 16.0402 12 16.0402Z" fill="#C0C0C0" />
                    <path d="M11.9976 9.14014C11.2404 9.14014 10.5142 9.44093 9.97879 9.97635C9.44337 10.5118 9.14258 11.2379 9.14258 11.9951C9.14258 12.7523 9.44337 13.4785 9.97879 14.0139C10.5142 14.5493 11.2404 14.8501 11.9976 14.8501C13.5676 14.8501 14.8576 13.5701 14.8576 12.0001C14.8576 10.4301 13.5676 9.14014 11.9976 9.14014Z" fill="#C0C0C0" />
                </svg>
            </div>
            <small class="text-danger" *ngIf="!arePasswordsEqual()"> {{'deuxchansidentiques' |translate}}</small>

            <div *ngIf=" (getPassword.invalid && getPassword.touched) || getPassword.dirty">
                <small *ngIf="getPassword.errors?.required" class="text-danger"> {{'passrequired'|translate}}</small>
                <small *ngIf="getPassword.errors?.pattern" class="text-danger"> {{'atleasttestpasswordmail'|translate}}</small>
            </div>
        </form>
    </div>


    <div>
        <p style="margin-block: 1rem"> {{'scannedtagobj' |translate}}</p>
        <form [formGroup]="addObjectForms" (ngSubmit)="onsubmit()">
            <div class="landing-page-content text-left">
                <div class="">
                    <textarea [placeholder]="'descriptionv5'|translate" class="inputNamepet w-100" name="description" required ngModel formControlName="description"></textarea>
                </div>
                <span *ngIf="(addObjectForms.controls['description'].invalid && addObjectForms.controls['description'].touched) ||
                addObjectForms.controls['description'].dirty">
                  <small *ngIf="addObjectForms.controls['description'].errors?.required"
                      class="text-danger">{{ "pets_label_descriptionrequired" | translate }}
                  </small>
                </span>

                <p style="margin-block: 0.5rem">{{'ownerobjchanged' |translate}}</p>
                <p style="margin-bottom: 0.2rem"> {{'thisisobv3' |translate}} </p>

                <select [(ngModel)]="selectedOption" class="selectCategoriePet w-100" formControlName="categorie" required>
                    <option *ngFor="let option of categorieObject?.object_types" [ngValue]="option">
                        {{ option.type_label }}
                    </option>
                </select>

                <mat-error class="d-block" *ngIf="(addObjectForms.controls['categorie'].invalid &&(addObjectForms.controls['categorie'].touched) ||addObjectForms.controls['categorie'].dirty)">
                    <small *ngIf="addObjectForms.controls['categorie'].errors?.required" class="text-danger">{{ "pets_label_categoryrequired" | translate }}</small>
                </mat-error>
            </div>
        </form>
    </div>

    <div style="text-align: center; margin-top: 1rem">
        <button class="signupNow" type="submit" (click)="onsubmit()" [disabled]="addObjectForms.invalid || formulaire.invalid || !arePasswordsEqual()" [ngStyle]="{opacity:formulaire.valid && addObjectForms.valid && arePasswordsEqual()? 1 : 0.5}">
          <span *ngIf="!isLoading">{{'landpageregistration_btn_registernow' | translate}}</span>
          <div class="d-flex justify-content-center">
              <mat-spinner color="primary" *ngIf="isLoading" [diameter]="25"></mat-spinner>
          </div>
        </button>
    </div>

    <div style="text-align: center; margin-top: 0.5rem">
        <span> {{'agreeregistlandpage' | translate}} </span>
        <p> {{'thelandingpage' | translate}}
            <span>
            <a href="https://foundme.com/terms-conditions/" target="_blank" id="linkterms" class="linkTermsPolicy">{{'registration_href_termsofuse' | translate}}</a>{{'andlandpagestepone' | translate}}
          </span>

            <a href="https://foundme.com/privacy-cookie-policy/" target="_blank" id="linkprivacy" class="linkTermsPolicy">{{'registration_href_privacypolicy' | translate}}</a>
        </p>
    </div>

    <div class="nextStep mb-3">
        <span> {{'nexsteplandpagett_v1' | translate}}</span>
        <div class="mt-1">
            <a [href]="getSafeUrl('https://apps.apple.com/pk/app/found-me/id1610984385/')" target="_blank">
                <img src="assets/img/app.png" height="30px" />
            </a>
            <a [href]="getSafeUrl('https://play.google.com/store/apps/details?id=com.found.me.prod/')" target="_blank">
                <img src="assets/img/play.png" height="44px" />
            </a>
        </div>
        <div class="d-flex mt-1">
            <img class="mr-1" src="assets/img/laptop.svg" width="200px" height="50px" />
            <span class="text-left mediaText">
              {{'wehavealsoawebapp' | translate}} {{'butrecusev3' | translate}} {{'appby_phonebtn' | translate}}
            </span>
        </div>
    </div>
</div>
