<div class="modal-body" id="popupmodel">
    <div class="backrow" [ngStyle]="{'background-color':color}">
    </div>
    <div class=" d-flex justify-content-center">

      <div *ngIf="type == 'error_v2' || type == 'success_v2'" class="cerclrinfo" style="padding: 11px;">
        <span *ngIf="type == 'error_v2'">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="65px" height="58px" viewBox="0 0 64 58" version="1.1">
          <g id="surface1">
          <path style=" stroke:none;fill-rule:nonzero;fill:rgb(92.54902%,10.980392%,25.098039%);fill-opacity:1;" d="M 56.570312 58.09375 L 7.300781 58.09375 C 4.703125 58.09375 2.296875 56.695312 0.996094 54.429688 C -0.308594 52.160156 -0.316406 49.363281 0.972656 47.085938 L 25.609375 3.699219 C 26.910156 1.410156 29.324219 0 31.9375 0 C 34.550781 0 36.964844 1.410156 38.265625 3.699219 L 62.898438 47.085938 C 64.191406 49.363281 64.183594 52.160156 62.878906 54.429688 C 61.574219 56.695312 59.171875 58.09375 56.570312 58.09375 Z M 32.191406 42.757812 C 31.070312 42.683594 29.972656 43.101562 29.179688 43.902344 C 28.386719 44.703125 27.972656 45.8125 28.046875 46.941406 C 28.003906 48.054688 28.414062 49.136719 29.183594 49.933594 C 29.988281 50.710938 31.074219 51.125 32.1875 51.074219 C 33.292969 51.140625 34.371094 50.726562 35.152344 49.933594 C 35.933594 49.144531 36.34375 48.058594 36.277344 46.945312 C 36.328125 45.816406 35.921875 44.71875 35.152344 43.898438 C 34.363281 43.125 33.292969 42.710938 32.191406 42.757812 Z M 28.90625 11.203125 L 29.464844 37.460938 L 34.863281 37.460938 L 35.398438 11.203125 Z M 28.90625 11.203125 "/>
          </g>
          </svg>
        </span>
        <span *ngIf="type == 'success_v2'">

            <svg class="svgcercle" xmlns="http://www.w3.org/2000/svg" width="56.3" height="60.307" viewBox="0 0 56.3 30.307">
            <g id="Groupe_9061" data-name="Groupe 9061" transform="translate(396.147 -348.847)">
              <line id="Ligne_1179" data-name="Ligne 1179" x2="18" y2="16" transform="translate(-390.5 367.5)" fill="none" stroke="#28a79a" stroke-linecap="round" stroke-width="8"/>
              <line id="Ligne_1180" data-name="Ligne 1180" x1="27" y2="29" transform="translate(-372.5 354.5)" fill="none" stroke="#28a79a" stroke-linecap="round" stroke-width="8"/>
            </g>
          </svg>

        </span>
        </div>
        <div *ngIf="type !== 'error_v2' && type !== 'success_v2'" class="cerclrinfo">
          <span *ngIf="type == 'error_v2'">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="65px" height="58px" viewBox="0 0 64 58" version="1.1">
            <g id="surface1">
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(92.54902%,10.980392%,25.098039%);fill-opacity:1;" d="M 56.570312 58.09375 L 7.300781 58.09375 C 4.703125 58.09375 2.296875 56.695312 0.996094 54.429688 C -0.308594 52.160156 -0.316406 49.363281 0.972656 47.085938 L 25.609375 3.699219 C 26.910156 1.410156 29.324219 0 31.9375 0 C 34.550781 0 36.964844 1.410156 38.265625 3.699219 L 62.898438 47.085938 C 64.191406 49.363281 64.183594 52.160156 62.878906 54.429688 C 61.574219 56.695312 59.171875 58.09375 56.570312 58.09375 Z M 32.191406 42.757812 C 31.070312 42.683594 29.972656 43.101562 29.179688 43.902344 C 28.386719 44.703125 27.972656 45.8125 28.046875 46.941406 C 28.003906 48.054688 28.414062 49.136719 29.183594 49.933594 C 29.988281 50.710938 31.074219 51.125 32.1875 51.074219 C 33.292969 51.140625 34.371094 50.726562 35.152344 49.933594 C 35.933594 49.144531 36.34375 48.058594 36.277344 46.945312 C 36.328125 45.816406 35.921875 44.71875 35.152344 43.898438 C 34.363281 43.125 33.292969 42.710938 32.191406 42.757812 Z M 28.90625 11.203125 L 29.464844 37.460938 L 34.863281 37.460938 L 35.398438 11.203125 Z M 28.90625 11.203125 "/>
            </g>
            </svg>
          </span>
            <span *ngIf="type == 'success'">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 611.999 611.999" style="enable-background:new 0 0 611.999 611.999;" xml:space="preserve">
              <g>
                <g>
                  <g>
                    <path d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203
                      C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578
                      c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626
                      h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856
                      c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626
                      C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32
                      c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082
                      c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z
                       M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826
                      c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485
                      c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"  fill="#28a79a"/>
                    <path d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258
                      c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258
                      C323.259,126.96,315.532,119.235,306.001,119.235z" fill="#28a79a"/>
                  </g>
                </g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              </svg>
          </span>
            <span *ngIf="type == 'error'">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 611.999 611.999" style="enable-background:new 0 0 611.999 611.999;" xml:space="preserve">
              <g>
                <g>
                  <g>
                    <path d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203
                      C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578
                      c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626
                      h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856
                      c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626
                      C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32
                      c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082
                      c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z
                       M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826
                      c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485
                      c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"  fill="#F4C809"/>
                    <path d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258
                      c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258
                      C323.259,126.96,315.532,119.235,306.001,119.235z" fill="#F4C809"/>
                  </g>
                </g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              </svg>
          </span>
        </div>
    </div>
    <div class="styletext">
        <span>{{title}}</span>
    </div>
    <div class="chatmsg text-center">
        <p>{{text}}</p>
    </div>
    <div>
        <div class="chatmsg text-center">
            <p>
            </p>
        </div>
        <div class="marg">
            <button id="myButton" type="button" class="bluebutton btn  btn-block  mt-2" [ngStyle]="{'background-color':color}" (click)="closemodel()">
            <label class ="continue">{{'Close' | translate }}</label>
          </button>
        </div>
    </div>

</div>
